import React, { useEffect, useState } from "react"
import styled from "styled-components"
import Headset from "../../../components/pictos/headset.component"
import Proptypes from "prop-types"
import { fontSizes, modules, colors, useThemeContext } from "../../structure/theme"
import WebCallbackForm from "./webCallbackForm.component"
import { Bold } from "../../structure/theme/styles.component"
import { graphql, useStaticQuery } from "gatsby"
import { Image } from "../../../components/images.component"
import { addEventTracking } from "../../thirdParties/gtm/tagManager"
import WebCallbackButton from "./webCallBackButton.component"
import { useTranslation } from "../../translation/translate.component"

const TextModal = styled.div`
  max-width: 500px;
  margin: 13px auto 20px;
  color: ${props => props.color || props.theme.colors.grey.g800};
`

const Utility = styled.div`
  max-width: 500px;
  margin: 22px auto;
  color: ${props => props.color || props.theme.colors.grey.g600};
  font-weight: 300;
  line-height: ${props => props.theme.fonts.sizes.small};
  font-size: ${props => props.theme.fonts.sizes.small};
`

const HeadsetContainer = styled.div`
  box-shadow: ${props => props.theme.shadows.callback};
  background-color: ${modules(`contact.wcb.backgroundColor`)};
  position: absolute;
  width: 45px;
  height: 45px;
  margin: auto auto auto 52%;
  border-radius: 50%;
  z-index: 20;
  top: 0px;
  & > svg {
    margin: 7px;
  }
`

const TitleModal = styled.h3`
  line-height: 35px;
  margin: 0;
  text-align: center;
  font-size: ${fontSizes(`x_large`)};
  color: ${colors(`primary`)};
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`

const FormContainer = styled.div`
  text-align: center;
  position: relative;
  padding-bottom: 60px;

  .grecaptcha-badge {
    position: absolute !important;
    transform: translateX(-20px);
  }
`

const TextCenter = styled.div`
  text-align: center;
`

const Message = styled.div`
  margin-bottom: 50px;
`

export default function WebCallbackModal ({ onClose, partnerCode, schedules }) {
  const { t } = useTranslation()
  const [answer, setAnswer] = useState(null)
  const { standardiste } = useStaticQuery(query)

  const {
    modules: {
      contact: {
        wcb: { color: headsetColor },
      },
    },
  } = useThemeContext()

  function onSubmit (status) {
    setAnswer(status)
  }

  function answerIS (status) {
    return answer === status
  }

  useEffect(() => {
    addEventTracking(`eventGA`, {
      eventCategory: `WebcallBack`,
      eventAction: `initié`,
      eventLabel: window.location.pathname,
    })
  }, [])

  return (
    <Container data-testid="webcallback_modal">
      <TextCenter>
        <Image file={standardiste} alt="standardiste" />
        <HeadsetContainer>
          <Headset size={32} color={headsetColor} />
        </HeadsetContainer>
      </TextCenter>
      <TextCenter>
        <TitleModal>
          <Bold fontWeight={700}>{t(`common:webcallback.title`)}</Bold>
        </TitleModal>
      </TextCenter>
      {(answerIS(null) || answerIS(`error`)) && (
        <FormContainer>
          <TextModal>
            {t(`common:webcallback.form_title`, { town: `Lyon` })}, <br />
            <Bold data-testid="webcallback_schedules">{schedules}</Bold>.
          </TextModal>
          <WebCallbackForm onSubmit={onSubmit} errorBack={answerIS(`error`)} partnerCode={partnerCode} />
          <Utility>* {t(`common:webcallback.form_phone_mention`)}</Utility>
        </FormContainer>
      )}
      {answerIS(`success`) && (
        <TextCenter data-testid="webcallback_modal_success">
          <Message>{t(`common:webcallback.success`)}</Message>
          <WebCallbackButton onClick={onClose} data-testid="webcallback_modal_button_close">
            {t(`common:generic.modal_close_button`)}
          </WebCallbackButton>
        </TextCenter>
      )}
    </Container>
  )
}

const query = graphql`
  query {
    standardiste: file(name: { eq: "wcb_standardiste" }) {
      childImageSharp {
        fixed(width: 85) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

WebCallbackModal.propTypes = {
  onClose: Proptypes.func.isRequired,
  partnerCode: Proptypes.string.isRequired,
  schedules: Proptypes.string.isRequired,
}

import React, { useState } from "react"
import styled from "styled-components"
import breakpoint from "styled-components-breakpoint"
import Proptypes from "prop-types"
import { tel, required, compose } from "../../../utils/validate"
import { telMask } from "../../../utils/mask"
import { postContactWcb } from "../contact.api"
import { colors } from "../../structure/theme"
import Phone from "../../../components/pictos/phone.component"
import Form from "../../../components/form/form.component"
import Field from "../../../components/form/field.component"
import { addEventTracking } from "../../thirdParties/gtm/tagManager"
import sha256 from "tiny-hashes/sha256"
import { sentryCaptureException } from "../../thirdParties/sentry"
import { Error } from "../../../components/form/infoAndError.component"
import WebCallbackButton from "./webCallBackButton.component"
import { useTranslation } from "../../translation/translate.component"
import { useCaptcha } from "../../../components/form/plugins/captcha.component"
import InputText from "../../../components/form/inputText.component"

const InputContainer = styled.div`
  border: 1px solid ${colors(`primary`)};
  border-radius: 2px;
  position: relative;
  width: 100%;
  background-color: ${props => props.bgColor || props.theme.colors.grey.g300};
  outline: 0;
  height: 50px;

  &::placeholder {
    color: ${props => props.theme.colors.grey.g600};
    font-style: italic;
    font-size: ${props => props.theme.fonts.sizes.regular};
  }

  input {
    height: 100%;
    width: 100%;
    border: none;
    padding-left: 10px;
    background-color: ${props => props.theme.colors.grey.g300};
  }

  ${breakpoint(`small`)`
    display: block;
    max-width: 300px;
    margin: 0 auto 5px auto;
  `}
`

const PictoContainer = styled.div`
  height: 22px;
  right: 10px;
  top: 14px;
  position: absolute;
`

const initialValues = {
  phone: ``,
}

function validate (values) {
  return {
    phone: compose(required, tel)(values.phone),
  }
}

export default function WebCallbackForm ({ onSubmit, errorBack, partnerCode }) {
  const { t } = useTranslation()
  const [disabled, setDisabled] = useState(false)
  const captcha = useCaptcha()

  function onSubmitWcb ({ captchaToken, ...values }) {
    addEventTracking(`eventGA`, {
      eventCategory: `WebcallBack`,
      eventAction: `Confirmé`,
      eventLabel: window.location.pathname,
      hashtel: sha256(values.phone),
    })
    setDisabled(true)

    return postContactWcb(values.phone, partnerCode, captchaToken)
  }

  function onSubmitSuccess (values, actions, data) {
    return onSubmit(data.result === `OK` ? `success` : `error`).then(() => {
      setDisabled(false)
    })
  }

  function onSubmitFail (values, actions, error) {
    sentryCaptureException(error)

    return onSubmit(`error`).then(() => {
      setDisabled(false)
    })
  }

  return (
    <Form
      name="webcallback"
      initialValues={initialValues}
      captcha={captcha}
      onSubmit={onSubmitWcb}
      onSubmitSuccess={onSubmitSuccess}
      onSubmitFail={onSubmitFail}
      validate={validate}
    >
      <InputContainer>
        <Field
          component={InputText}
          type="tel"
          name="phone"
          mask={telMask}
          placeholder={t(`common:webcallback.form_phone_label`) + `*`}
        />
        <PictoContainer>
          <Phone size={20} />
        </PictoContainer>
      </InputContainer>
      {errorBack && <Error data-testid="webcallback_modal_error_server">{t(`common:generic.form_error`)}</Error>}
      <WebCallbackButton data-testid="webcallback_modal_submit" disabled={disabled} type="submit">
        {t(`common:webcallback.form_submit`)}
      </WebCallbackButton>
    </Form>
  )
}

WebCallbackForm.propTypes = {
  onSubmit: Proptypes.func.isRequired,
  errorBack: Proptypes.bool.isRequired,
  partnerCode: Proptypes.string,
}
